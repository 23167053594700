var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "quickSearchRegistryModal",
        size: "xl",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "people" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Cerca Anagrafiche")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSearch(_vm.filterName)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "b-card",
                { staticClass: "filter" },
                [
                  _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c("base-input", {
                          attrs: {
                            name: "RFRNAME",
                            type: "text",
                            label: "Nominativo",
                            placeholder: "Inserisci un nominativo",
                          },
                          model: {
                            value: _vm.filter.byAttribute.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byAttribute, "title", $$v)
                            },
                            expression: "filter.byAttribute.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-6 align-self-center actions-wrapper",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("Cerca")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-reset",
                            attrs: {
                              type: "button",
                              variant: "lisaweb",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onClearFilter(_vm.filterName)
                              },
                            },
                          },
                          [_vm._v("Reset")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-3",
                          arg: "collapse-3",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n          Altri Filtri\n        "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      staticClass: "mt-2",
                      attrs: { id: "collapse-3" },
                      model: {
                        value: _vm.visible,
                        callback: function ($$v) {
                          _vm.visible = $$v
                        },
                        expression: "visible",
                      },
                    },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "NINO",
                                type: "text",
                                label: "Codice Fiscale",
                                placeholder: "Inserisci un codice fiscale",
                              },
                              model: {
                                value: _vm.filter.byAttribute.NINO,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byAttribute, "NINO", $$v)
                                },
                                expression: "filter.byAttribute.NINO",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "creg",
                                type: "text",
                                label: "Partita IVA",
                                placeholder: "Inserisci una partita IVA",
                              },
                              model: {
                                value: _vm.filter.byCustom.CREG.value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byCustom.CREG,
                                    "value",
                                    $$v
                                  )
                                },
                                expression: "filter.byCustom.CREG.value",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "salesman",
                                label: "Produttore",
                                options: _vm.salesmen,
                                multiple: false,
                              },
                              model: {
                                value: _vm.filter.byBroker.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byBroker, "id", $$v)
                                },
                                expression: "filter.byBroker.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "localita",
                                label: "Località",
                                placeholder: "Digitare le lettere iniziali...",
                                options: _vm.ajaxOptions,
                                isLoading: _vm.isLoadingAjax,
                              },
                              on: { "search-change": _vm.getAjaxOptions },
                              model: {
                                value: _vm.filter.byCorrespondence.CITY,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byCorrespondence,
                                    "CITY",
                                    $$v
                                  )
                                },
                                expression: "filter.byCorrespondence.CITY",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("base-textarea", {
                              attrs: {
                                name: "ADDR",
                                label: "Indirizzo",
                                placeholder: "Inserisci un indirizzo",
                              },
                              model: {
                                value: _vm.filter.byCustom.ADDR.value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byCustom.ADDR,
                                    "value",
                                    $$v
                                  )
                                },
                                expression: "filter.byCustom.ADDR.value",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("registries", {
        ref: _vm.tableRef,
        attrs: {
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          filterOn: {
            byRelations: ["byCorrespondence", "byRegistryGroup", "byRegistry"],
          },
          noInnerWidth: "",
          noActions: "",
          noCustom: "",
          includeRowSelector: "",
          noInputPaginator: "",
        },
        on: { rowSelector: _vm.onModalSelector },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }